import React from "react";

const ScrollDownIcon = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 18H13V2L11 2V18H8L12 22L16 18Z" fill="currentColor"></path>
    </svg>
  );
};

export default ScrollDownIcon;
