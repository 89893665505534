import React, { useReducer } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";

import NoScrollBody from "@components/NoScrollBody";

import overlayReducer from "@reducers/overlay";

import { OverlayDispatch } from "@contexts/overlay";

const defaultOverlay = null;

const Layout = ({ children, className = "" }) => {
  const [activeOverlay, dispatch] = useReducer(overlayReducer, defaultOverlay);
  const isOverlayDisplayed = activeOverlay !== null;

  return (
    <div className={`layout ${className}`}>
      <OverlayDispatch.Provider value={dispatch}>
        {children}
        {isOverlayDisplayed && <NoScrollBody />}
        <TransitionGroup component={null}>
          {isOverlayDisplayed && (
            <CSSTransition
              in={isOverlayDisplayed}
              classNames="overlay"
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
            >
              {React.createElement(activeOverlay)}
            </CSSTransition>
          )}
        </TransitionGroup>
      </OverlayDispatch.Provider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Layout;
