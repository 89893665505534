import * as actions from "@actions/overlay";

export default function reducer(state, action) {
  switch (action.type) {
    case actions.OPEN_OVERLAY:
      if (state === action.overlay) {
        return state;
      }

      return action.overlay;

    case actions.CLOSE_OVERLAY:
      return null;

    default:
      throw new Error(`Unknown overlay action: ${action.type}`);
  }
}
