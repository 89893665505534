import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { getImage } from "gatsby-plugin-image";
import camelCase from "lodash/camelCase";

import Layout from "@components/Layout";
import SEO from "@components/SEO";
import BackIcon from "@components/BackIcon";
import HashLink from "@components/HashLink";
import Link from "@components/Link";
import ScrollDownIcon from "@components/ScrollDownIcon";

const BlogPost = ({ data, pageContext, location }) => {
  const { t } = useTranslation();
  const [isBlogLinkOnCover, setIsBlogLinkOnCover] = useState(false);

  const { frontmatter, timeToRead, body } = data.mdx;

  const images = data.images?.nodes?.reduce((acc, img) => {
    acc[camelCase(img.name)] = getImage(img);
    return acc;
  }, {});

  let canonicalPath = "";

  if (frontmatter.canonicalVersion !== pageContext.locale) {
    const { originalPath } = pageContext;
    const { defaultLang, prefixDefault } = data.themeI18N;
    const langPrefix =
      frontmatter.canonicalVersion === defaultLang && !prefixDefault
        ? ""
        : `/${frontmatter.canonicalVersion}`;

    canonicalPath = `${langPrefix}${originalPath}`;
  }

  useEffect(() => {
    const blogLink = document.querySelector(".blog-link");
    const cover = document.querySelector(".cover");
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBlogLinkOnCover(entry.isIntersecting);
      },
      {
        rootMargin: `-${
          blogLink.clientHeight -
          parseInt(getComputedStyle(blogLink).paddingBottom)
        }px`,
      }
    );

    observer.observe(cover);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Layout className="blog-post-page">
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        lang={frontmatter.canonicalVersion}
        pathname={location.pathname}
        canonicalPath={canonicalPath}
      />
      <main>
        <Link
          className={`blog-link ${isBlogLinkOnCover ? "light" : ""}`}
          href="/blog/"
          aria-label={t("blogPost.blogLinkAria")}
        >
          <BackIcon />
        </Link>
        <article>
          <div className="cover">
            <div className="inner">
              <h1>{frontmatter.title}</h1>
              <div className="read-time">
                {t("blogPost.timeToRead", { minutes: timeToRead })}
              </div>
              <HashLink hash="content">
                <ScrollDownIcon />
              </HashLink>
            </div>
          </div>
          <div id="content" className="content">
            <MDXRenderer images={images}>{body}</MDXRenderer>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPost($locale: String!, $slug: String!, $imagesDir: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        slug
        title
        description
        canonicalVersion
      }
      body
      timeToRead
    }
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "blog" }
        relativeDirectory: { eq: $imagesDir }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
    themeI18N {
      prefixDefault
      defaultLang
    }
  }
`;
