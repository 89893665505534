import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const HashLink = ({ hash, children }) => {
  const { t } = useTranslation();
  const handleScroll = (e) => {
    e.preventDefault();

    document.getElementById(hash).scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <a
      className="hash-link"
      href={`#${hash}`}
      aria-label={t("hashLink.label", { hash })}
      onClick={handleScroll}
    >
      {children}
    </a>
  );
};

HashLink.propTypes = {
  hash: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default HashLink;
