import { useEffect } from "react";

import { scrollTop } from "@utils/view";

const useScrollTop = (condition) => {
  useEffect(() => {
    if (condition === true) {
      scrollTop();
    }
  }, [condition]);
};

export default useScrollTop;
