import React from "react";
import { LocalizedLink } from "gatsby-theme-i18n";
import PropTypes from "prop-types";

const Link = ({
  href,
  children,
  language = null,
  outside = false,
  ...passThroughProps
}) => {
  return outside ? (
    <a href={href} {...passThroughProps}>
      {children}
    </a>
  ) : (
    <LocalizedLink to={href} language={language} {...passThroughProps}>
      {children}
    </LocalizedLink>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  language: PropTypes.string,
  outside: PropTypes.bool,
};

export default Link;
