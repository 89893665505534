import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { LocaleContext } from "gatsby-theme-i18n";
import PropTypes from "prop-types";

import OgImage from "@images/og-image.jpg";

const SEO = ({
  title,
  description,
  lang = "",
  meta = [],
  pathname = "/",
  canonicalPath = "",
}) => {
  const { site } = useStaticQuery(
    graphql`
      query SeoQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  );

  const defaultTitle = site.siteMetadata?.title;
  const siteUrl = site.siteMetadata?.siteUrl;
  const locale = useContext(LocaleContext);
  const links = [];

  if (canonicalPath) {
    links.push({ rel: "canonical", href: `${siteUrl}${canonicalPath}` });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || locale,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={links.length > 0 ? links : undefined}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteUrl + pathname,
        },
        {
          property: `og:image`,
          content: siteUrl + OgImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ].concat(meta)}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  lang: PropTypes.string,
  meta: PropTypes.array,
  pathname: PropTypes.string,
  canonicalPath: PropTypes.string,
};

export default SEO;
